import React, { BaseSyntheticEvent, useState } from 'react';
import './IssuanceProcess.css';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomButton from '../../components/CustomButton/CustomButton';
import withHeader from '../../hoc/withHeader';
import { issueCredential } from '../../services/vidcredentials';
import { CredentialTypes, CredentialUrls } from '../../dtos/credentials';
import BACKEND from '../../config';

function OptionsEstudiantTitulat() {
  return (
    <>
      <option>
        Grau en Enginyeria
        Informàtica
      </option>
      <option>
        Grau en Administració i Direcció d&apos;Empreses
        (ADE)
      </option>
    </>
  );
}

function OptionsCAO() {
  return (
    <>
      <option>
        Grau en Enginyeria
        Telecomunicacions
      </option>
      <option>
        Grau en Dret Laboral
      </option>
    </>
  );
}

function OptionsNivellIdiomes() {
  return (
    <>
      <option>
        Català
      </option>
      <option>
        Anglès
      </option>
    </>
  );
}
function IssuanceProcess() {
  const navigate = useNavigate();
  const [degree, setDegree] = useState<string>('');
  const { state } = useLocation();

  const handleChange = (e: BaseSyntheticEvent) => {
    if (e.target.name === 'degree') {
      setDegree(e.target.value);
    }
  };

  const handleClick = async () => {
    const accessToken = localStorage.getItem('access_token');
    if (!accessToken) {
      navigate('/issuance-process/failure', { state: { retryLocation: CredentialUrls.ISSUE_CREDENTIAL } });
      return;
    }
    let response;
    const mecdProgramCode = '2500442';
    if (state.credential === CredentialTypes.ESTUDIANT_TITULAT) {
      response = await issueCredential(
        accessToken,
        { dni: localStorage.getItem('ID-EstudiantTitulat'), mecdProgramCode },
        BACKEND.ENDPOINTS.ISSUANCE_ESTUDIANT_TITULAT,
      );
    }

    if (state.credential === CredentialTypes.NIVELL_IDIOMES) {
      response = await issueCredential(
        accessToken,
        { dni: localStorage.getItem('ID-NivellIdiomes'), idioma: 'CA' },
        BACKEND.ENDPOINTS.ISSUANCE_NIVELL_IDIOMES,
      );
    }

    if (state.credential === CredentialTypes.CAO) {
      response = await issueCredential(
        accessToken,
        { dni: localStorage.getItem('ID-EducationalID'), mecdProgramCode: '2500442', documentationType: 'DNI' },
        BACKEND.ENDPOINTS.ISSUANCE_CAO,
      );
    }

    if (!!response && 'error' in response) {
      navigate('/issuance-process/failure', { state: { retryLocation: CredentialUrls.ISSUE_CREDENTIAL } });
      return;
    }
    navigate('/issuance-process/success', { state: { credential: state.credential } });
  };

  return (
    <div className="content-container">
      <h1 className="content-container__header-1">Detalls de la credencial</h1>
      <h2 className="content-container__header-2">
        Si us plau, selecciona els
        detalls de la credencial a emetre.
      </h2>
      <div className="inputs-container">
        <select
          className="inputs-container__input"
          id="degree"
          placeholder="Grau d'informàtica"
          name="degree"
          value={degree}
          onChange={handleChange}
        >
          {
            (state.credential === CredentialTypes.ESTUDIANT_TITULAT)
            && <OptionsEstudiantTitulat />
          }
          {
            (state.credential === CredentialTypes.NIVELL_IDIOMES)
            && <OptionsNivellIdiomes />
          }
          {
            (state.credential === CredentialTypes.CAO)
            && <OptionsCAO />
          }
        </select>
        <CustomButton
          handleClick={handleClick}
          text="Emetre Credencial"
          showArrow
        />
      </div>
    </div>
  );
}

export default withHeader(IssuanceProcess);
